<template>
  <div class="order-main yt-flex-layout">
    <div class="bread-div yt-flex-layout">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item>订单列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="commodity-content">
      <div class="content-card">
        <div class="content-header yt-flex-layout">
          <div class="header-left">
            <el-button type="primary" class="btn" size="small">
              <div>
                <JYIcon :href="'#icon-daochu'" />
                <p>导出订单</p>
              </div>
            </el-button>
          </div>
          <div class="header-right">
            <el-input class="el-common--style" v-model.trim="searchData.userName" placeholder="请输入商品名称" />
            <el-date-picker
              class="el-common--style time-style"
              v-model="searchData.startTime"
              type="datetime"
              prefix-icon="el-icon-date"
              placeholder="请选择时间"
            />
            <el-select v-model="searchData.state" class="el-common--style" placeholder="请选择状态">
              <el-option label="全部" :value="null" />
              <el-option label="未支付" :value="0" />
              <el-option label="已完成" :value="1" />
              <el-option label="已失效" :value="8" />
            </el-select>
            <el-button type="primary" class="btn1" size="small" @click="getList">查询</el-button>
            <el-button class="btn1" size="small" @click="reset">重置</el-button>
          </div>
        </div>
        <div class="content-table">
          <div class="el-table--style">
            <el-table class="yt-table" :data="tableList" size="small" stripe style="width: 100%">
              <el-table-column type="selection" width="60" align="center" />
              <el-table-column
                v-for="(c, index) in columns"
                :key="index"
                :label="c.title"
                :align="c.align"
                :width="c.width"
                :sortable="c.sortable"
                :prop="c.key"
                :min-width="c.minWidth"
              >
                <template slot-scope="scope">
                  <div v-if="c.key === 'img'">
                    <img :src="scope.row[c.key]" width="30" height="30" alt="" />
                  </div>
                  <div v-else-if="c.key === 'status'" class="text-style">
                    <span
                      class="status-span yt-flex-layout flexCenter"
                      :style="{
                        backgroundColor: bgColorList[scope.row.status],
                        color: fontColorList[scope.row.status]
                      }"
                      >{{ scope.row.status | getStatus }}</span
                    >
                  </div>
                  <div v-else-if="c.key === 'orderTime'" class="text-style">
                    <span>{{ $formatTime(scope.row[c.key], 'yyyy-MM-dd hh:mm') }}</span>
                  </div>
                  <div v-else class="text-style" :style="c.key === 'orderPrice' ? 'padding-right: 24px !important;' : ''">{{ scope.row[c.key] }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <span class="tool-button" @click="getInfo(scope.row)">详情</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="el-page--style yt-flex-layout">
            <Page
              class="yt-page"
              :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
              :total="total"
              :current="searchData.pageNumber + 1"
              :page-size-opts="[10, 20, 40, 100]"
              :page-size="searchData.size"
              show-elevator
              show-sizer
              show-total
              @on-change="changePage"
              @on-page-size-change="changeSize"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JYIcon from '@components/common/JYIcon'
import { orderColumns } from '@components/manage/commodity/commodityColumns'
import orderApi from '@api/order'
export default {
  name: 'OrderList',
  components: { JYIcon },
  data() {
    return {
      total: 0,
      timeRange: '',
      bgColorList: ['#EFFFF4', '#EDF4FF', '#FFEEEE', '#ECECEC'],
      fontColorList: ['#4CE47A', '#448bff', '#FF5050', '#666666'],
      tableList: [],
      searchData: {
        pageNumber: 0,
        endTime: null,
        startTime: null,
        pageSize: 10,
        userName: null,
        status: null
      },
      columns: orderColumns
    }
  },
  created() {
    this.getList()
  },
  filters: {
    getStatus(type) {
      switch (type) {
        case 0:
          return '未支付'
        case 1:
          return '已完成'
        case 8:
          return '已失效'
      }
    }
  },
  methods: {
    getList() {
      orderApi.getOrderList(this.searchData).then(res => {
        if (res.code === 0) {
          this.total = res.res.total
          this.tableList = res.res.data.map(data => {
            return {
              img: data.buyer.buyerAvatar,
              userName: data.buyer.buyerName,
              orderNum: data.orderNo,
              orderId: data.orderId,
              buyerId: data.buyer.buyerId,
              productId: data.product.productId,
              name: data.product.productName,
              orderPrice: data.payAmount,
              validityTime: data.timeLimit,
              status: data.state,
              orderTime: data.createTime
            }
          })
        }
      })
    },
    reset() {
      this.searchData = {
        pageNumber: 0,
        endTime: null,
        startTime: null,
        pageSize: 10,
        userName: null,
        status: null
      }
      this.getList()
    },
    getInfo(data) {
      this.$router.push({ path: '/commodity/order/info', query: { orderId: data.orderId } })
    },
    changePage(val) {
      this.searchData.pageNumber = val - 1
      this.getList()
    },
    changeSize(val) {
      this.searchData.pageNumber = 0
      this.searchData.pageSize = val
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.order-main {
  min-width: 1200px;
  background-color: #f8f8f8;
  flex-direction: column;
  height: 100%;
  .bread-div {
    align-items: center;
    padding: 0 12px;
    height: 44px;
    background-color: #fff;
    margin-bottom: 20px;
  }
  .commodity-content {
    height: calc(100% - 64px);
    padding: 0 20px;
    .content-card {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      border-radius: 6px;
      height: 100%;
    }
    .content-header {
      padding: 0 20px;
      height: 60px;
      justify-content: space-between;
      align-items: center;
      .el-common--style {
        width: 200px;
        margin-right: 12px;
      }
      .btn1 {
        .wh(80px, 32px);
      }
      .btn {
        height: 32px;
      }
      .header-right {
        ::v-deep .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
        ::v-deep .el-input__icon {
          line-height: 32px;
        }
      }
    }
    .content-table {
      height: calc(100% - 60px);
      .el-table--style {
        padding: 0 20px;
        height: calc(100% - 65px);
        .status-span {
          font-size: 12px;
          .wh(60px, 20px);
          border-radius: 10px;
        }
        .text-style {
          /*padding-right: 24px !important;*/
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
      .tool-button {
        cursor: pointer;
        color: #448bff;
        font-size: 12px;
        margin-right: 10px !important;
        user-select: none;
        &:hover {
          color: #07409d;
        }
      }
      .el-page--style {
        align-items: center;
        padding: 0 30px;
        height: 52px;
        ::v-deep .yt-page {
          width: 100% !important;
        }
      }
    }
  }
}
.time-style {
  ::v-deep .el-input__inner {
    padding-left: 10px !important;
  }
  ::v-deep .el-input__prefix {
    right: 5px !important;
    left: auto !important;
  }
}
</style>
